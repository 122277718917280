.btn {
    margin-top: 10px;
}

.buttons-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.tight-row {
    display: flex;
    flex-direction: row;
}

.destructive-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

@media only screen and (min-width: 600px) {
    .backbutton {
        float: right;
    }
}
