.actions {
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
    justify-content: center;
    margin: 15px 25px;
}

.info-container{
    margin: 15px 25px;
}

.collapsible {
    margin: 0px;
    padding: 0px;
    margin-left: 25px;
}

.collapsible-trigger-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.collapsible-final-content {
    padding-left: 10px;
}

@media only screen and (max-width: 500px) {
    .actions {
       gap: 15px;
       margin: 5px 5px;
    }

    .collapsible{
        margin-left: 14px;
    }

    .info-container {
        margin: 5px 5px;
    }
}



