.memberlist {
    overflow-x: auto;
}

.memberlist tr {
    cursor: pointer;
}

.memberlistTable {
    width: calc(100vw - 30px) !important;
}


.csvButton {
    margin-top: 10px;
}

.membercount p, .functionDescText p {
    margin-top: 1rem;
}
.notClickable {
    opacity: 0.5 !important;
    color: black !important;
    background-color: #fecd32 !important;
    cursor: not-allowed !important;
}

.functionsMemberList {
    overflow-y: scroll;
    max-height: 500px;
}
.functionsMemberList tr {
    cursor: pointer;
}
.spacing {
    margin-top: 1rem;
}