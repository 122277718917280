#root {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    height: 100%;
}

.btn {
    margin-right: 5px;
    border-radius: 5px;
    border: 0;
    background-color: #fecd32;
    color: black;
}

.btn.danger {
    background-color: #FF4646;
    color: black;
}

.btn:hover:not(:disabled) {
    background-color: black;
    color: #fecd32;
}


.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show > .btn-success.dropdown-toggle {
    color: #000;
    background-color: #333;
    border-color: #fecd32;
}

.btn:disabled {
    opacity: 0.4;
    background-color: #fecd32;
    color: black;
}
.header-container {
    height: 58px;
}
.container {
    min-height: calc(100vh - 58px - 15px - 15px - 192px); /* full height - header(58px) - header's margin btm(15px) - container's margin btm (15px) - footer(192px) */
    margin-bottom: 15px;
}

table {
    font-family: 'Courier', serif;
}

a {
    color: #fecd32;
}

a:hover {
    color: #6e560a;
    text-decoration: none;
}

.form-control:focus {
    box-shadow: 0 0 0 0.2rem #fecd32;
}
