.shop-item {
    margin: 15px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 45%;
    max-width: 45%;
    min-width: 45%;
}

@media(max-width: 768px) {
    .shop-item {
        width: 90%;
        max-width: 90%;
        min-width: 90%;
    }
}