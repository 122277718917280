.header-container {
    margin-bottom: 15px;
}

.header-container p {
    color: #000;
    display: inline;
}

.header-container img {
    filter: brightness(0%);
}

.navbar {
    background-color: #fecd32;
    box-shadow: 0 0 0.7em #8e8d8d;
    z-index: 10;
}

.navbar-nav a {
    color: #000 !important;
}

.navbar-nav a:hover {
    color: #fecd32 !important;
    background-color: black;
    transition: all 0.3s ease-in-out;
    transition-delay: 0s;
    transition-duration: 0.3s;
    transition-property: all;
    transition-timing-function: ease-in-out;
}