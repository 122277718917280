.announcement-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 25px;
}

.buttons-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.details-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.announcer {
    display: flex;
    flex-direction: row;
   gap: 10px;
    text-align: right;
}

.language-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.language-select {
    max-width: 100px;
}

.announcer img{
    width: 50px;
    height: 50px;
    border-radius: 50%;

}

.destructive-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    gap: 5px;
}

.attachments-col{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

#file-picker {
    display: none;
}

.attachment-row{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.single-attachment{
    background-color: #F6F6F6;
    border-radius: 10px;
    margin: 5px;
    padding: 5px 20px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.single-attachment .remove {
    cursor: pointer;
    color: red;
    font-size: 22px;
    font-weight: 800;
}

@media only screen and (max-width: 500px) {
    .announcement-form {
        gap: 10px;
        margin: 5px;
    }

    .buttons-row {
        gap: 5px;

    }

    .destructive-buttons {
        gap: 5px;
    }
}
.attachment-link {
    background-color: #F6F6F6;
    padding: 10px 10px;
    border-radius: 5px;
    width: fit-content;
    color: #d49707;
    font-weight: bold;
}