.footer-container {
    min-height: 192px;
    background-color: #F2F2F2;
    padding: 50px;
    margin-top: 15px;
}
.footer-container > * {
    color: #000;
    font-weight: bold;
    display: block;
    text-align: center;
}
.footer-container > *:not(:first-child) {
    margin-top: 10px;
}