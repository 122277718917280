.title-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.single-announcement {
    margin: 15px 20px;
    padding: 15px 10px;
    border: 0.5px solid black;
    background-color: #F6F6F6;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.filter {
    margin: 5px;
}

.single-announcement-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2px;
}

.single-announcement-status.draft {
    color: #AF1414;
}

.single-announcement-status.scheduled {
    color: #DEB00D;
}

.single-announcement-status.published {
    color: #09941F;
}

.single-announcement-status.archived {
    color: #a2a2a2;
}

.single-announcement-status.template {
    color: #dfb52b;
}

.announcements {
    display: flex;
    flex-direction: column;
    margin: 20px;
}

