.auth {
    background-image: linear-gradient(to bottom right, #000000, #464646);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    padding-top: 80px;
}

.smalljumbo {
    width: 400px;
}

.bigjumbo {
    width: 80%;
}

@media only screen and (max-width: 1100px) {
    .bigjumbo {
        width: 95%;
    }
}

.jumbotron {
    background-color: #00000080;
    border: 2px solid #000;
}

.jumbotron img {
    margin-bottom: 20px;
}

.jumbotron h3 {
    font-weight: 600;
    text-transform: uppercase;
}

.btm-links {
    font-size: 0.8rem;
    margin: 2px 0 0 0;
}
